<div class="main-f-card">
<ng-container *ngIf="!startedProcess; else elseProcess">
  <div class="grid px-4">
    <div class="col-12 mt-4 mb-2">
      <p>
        Para la solicitud de cualquier producto debes contar con una cuenta validada y conectada con nuestro sistema, esto nos ayudara a realizar los cobros en los tiempos correctos, asegúrate de dar de alta tu cuenta de nómina, esto agilizara la aprobación del crédito y todo durante el proceso.
      </p>
    </div>
    <div class="col-12 mb-4 comment-rejected" *ngIf="data.comment && data.comment !== ''">
      Motivo de rechazo: <strong>{{data.comment}}</strong>
    </div>
    <div class="col-12">
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      </div>
    </div>
    <div class="col-12">
      <div class="img img-start"></div>
    </div>
    <div class="col-12 button-block-container my-5">
      <button pButton pRipple label="Empezar proceso" icon="fa-solid fa-fingerprint p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="start()" [disabled]="!startButtonIsAvailable"></button>
    </div>
  </div>
</ng-container>
<ng-template #elseProcess>
  <div class="grid px-4">
    <div class="col-12 my-4">
      <p>
        Si has completado el proceso de validación de tu cuenta de nómina, da clic en he concluido el proceso, revisaremos tu información y podras continuar.
      </p>
    </div>

    <div class="col-12">
      <div class="img img-complete"></div>
    </div>
    <div class="col-12">
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      </div>
    </div>
    <div class="col-12 button-block-container my-5">
      <button pButton pRipple label="He concluido el proceso" icon="fa-solid fa-fingerprint p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="getUrl()"></button>
    </div>
  </div>
</ng-template>
</div>
