import { Component } from '@angular/core';
import {faFileInvoice, faScaleBalanced} from "@fortawesome/free-solid-svg-icons";
import {ActivatedRoute} from "@angular/router";
import {FinsusService} from "../../../../data/services/page/finsus.service";

@Component({
  selector: 'app-balance-state',
  templateUrl: './balance-state.component.html',
  styleUrls: ['./balance-state.component.scss']
})
export class BalanceStateComponent {

    protected readonly faFileInvoice = faFileInvoice;
  protected readonly faScaleBalanced = faScaleBalanced;
  constructor(
    private activatedRoute: ActivatedRoute,
    private finsusService: FinsusService,
  ) {
    console.log("here")
    this.activatedRoute.queryParamMap.subscribe((r:any) => {
      console.log('haber', r)
      if (r.params && r.params.finsusAction) {
        console.log("inside 2")
        this.finsusService.setParameters(r.params.finsusAction, r.params.token, r.params.data)
        console.log("finsusAction", this.finsusService.action, this.finsusService.token)
        this.finsusService.runProcess()

        //this.router.navigate(['/login'])
        // http://localhost:6100/?finsusAction=LOAN_DETAIL&token=eyJyZmMiOiJNQUxJOTIxMjI2SVcyIiwiY3VycCI6Ik1BTEk5MjEyMjZIREZSUlYwMyIsImVtYWlsIjoiaXZhbkBwdWxwaS5teCIsInB1bHBpLWtleSI6IjEyMzQ1NmFzMiIsImNsYWJlRmluc3VzIjoiMTIzNDQ0NDQ0NDQ0NDQyMzMyIiwiY3VycmVudExvYW5JZCI6IjY2ZmFmOTZlM2JjNWY2Njg4ZjMwZjRiMyJ9&data=eyJyZmMiOiJNQUxJOTIxMjI2SVcyIiwiY3VycCI6Ik1BTEk5MjEyMjZIREZSUlYwMyIsImVtYWlsIjoiaXZhbkBwdWxwaS5teCIsInB1bHBpLWtleSI6IjEyMzQ1NmFzMiIsImNsYWJlRmluc3VzIjoiMTIzNDQ0NDQ0NDQ0NDQyMzMyIiwiY3VycmVudExvYW5JZCI6IjEyMzQiLCJwaG9uZSI6IjU1NjY3Nzg4OTkiLCJmaXJzdE5hbWUiOiJKT1NFIiwic2Vjb25kTmFtZSI6IklWQU4iLCJmYXRoZXJMYXN0TmFtZSI6Ik1BUlRJTkVaIiwibW90aGVyTGFzdE5hbWUiOiJMQVJJT1MiLCJpbmNvZGVJZCI6IjY2NzI3NzI5ODM5Mjk4MyJ9
      }
    })
  }
}
