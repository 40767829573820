import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-onboarding-create-credentials',
  templateUrl: './onboarding-create-credentials.component.html',
  styleUrls: ['./onboarding-create-credentials.component.scss']
})
export class OnboardingCreateCredentialsComponent {
  @Input() entity!: FormGroup
  @Output() onSendData = new EventEmitter()
  sendData() {
    this.onSendData.emit()
  }
  whatsapp() {
    window.open('https://wa.me/525580284233?text=Hola+necesito+ayuda','_blank')
  }

  validateRFC() {
    console.log(this.entity.value.rfc)
    const rfcControl = this.entity.get('rfc');
    if (rfcControl) {
      let cleanedRFC = rfcControl.value.trim();
      cleanedRFC = cleanedRFC.toUpperCase()
      rfcControl.setValue(cleanedRFC);
      /*if (cleanedRFC.length !== 13) {
        rfcControl.setErrors({length: true});
      } else {
        rfcControl.updateValueAndValidity();
      }*/
    }
  }

  validateCURP() {
    console.log(this.entity.value.curp)
    const curpControl = this.entity.get('curp');
    if (curpControl) {
      let cleanedCURP = curpControl.value.trim();
      cleanedCURP = cleanedCURP.toUpperCase()
      curpControl.setValue(cleanedCURP);

    }
  }
}
