<div class="main-f-card">
<ng-container *ngIf="!startedProcess; else elseProcess">
  <div class="grid px-4">
    <div class="col-12 mt-4 mb-2">
      <p>
        Para continuar con tu verificación de identidad es necesario que tengas a la mano tu INE, después de validar este documento te tomaremos una selfie para validar que este documento oficial es de tu pertenencia.
      </p>
    </div>
    <div class="col-12 mb-4 comment-rejected" *ngIf="data.comment && data.comment !== ''">
      Motivo de rechazo: <strong>{{data.comment}}</strong>
    </div>
    <div class="col-12">
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      </div>
    </div>
    <div class="col-12">
      <div class="img img-start"></div>
    </div>
    <div class="col-12 button-block-container my-5">
      <button pButton pRipple label="Empezar proceso" icon="fa-solid fa-fingerprint p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="start()" [disabled]="!startButtonIsAvailable"></button>
    </div>
  </div>
</ng-container>
<ng-template #elseProcess>
  <div class="grid px-4">
    <div class="col-12 my-4">
      <p>
        Si has completado el proceso de validación de identidad, da clic en he concluido el proceso, revisaremos tu información y podrás continuar.
      </p>
    </div>

    <div class="col-12">
      <div class="img img-complete"></div>
    </div>
    <div class="col-12">
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      </div>
    </div>
    <div class="col-12 button-block-container my-5">
      <button pButton pRipple label="He concluido el proceso" icon="fa-solid fa-fingerprint p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="validateUserIdentity()"></button>
    </div>
  </div>
</ng-template>
</div>
