import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IFinsusData, IFinsusToken} from "../../interfaces/ifinsus.interface";
import {LoaderService} from "./loader.service";
import {PushingService} from "../api/flow/pushing.service";
import {Message} from "primeng/api";
import {AuthService} from "../modules/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class FinsusService {
  action = ''
  token!: IFinsusToken
  data!: IFinsusData
  responseMessage: Message[] = []
  flowId = 'PULPI_LOAN'
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private pushingService: PushingService,
    private authService: AuthService
  ) {

  }

  setParameters(action: string, token: string, data: string) {
    // TODO: save data on session
    this.action = action
    this.token = JSON.parse(atob(token))

    /*if (action === 'NEW_ACCOUNT' && data) {
      this.data = JSON.parse(atob(data))
    }*/
  }

  getRFC(token: string) {
    const t =  JSON.parse(atob(token))
    return t.rfc
  }

  runProcess() {
    console.log("calling and close", this.action)
    if(this.authService.getUser && !this.authService.hasValidToken(this.token)) {
      console.log("mira saliendo")
      this.authService.logout()
      this.action = 'NEW_ACCOUNT_LOGOUT'
    }
    if (this.action !== '') {
      switch (this.action) {
        case 'NEW_ACCOUNT_LOGOUT':
          this.action = ''
          this.router.navigate(['/'])
          break;
        case 'NEW_ACCOUNT':
          console.log("in account ===", this.authService.getUser?.user.hasFlowInProcess)
          this.router.navigate([this.authService.getUser?.user.hasFlowInProcess ? '/verify-documents' : `/onboarding`])
          break;
        case 'NEW_LOAN':
          //TODO: Create flow
          if (this.authService.getUser?.user) {
            console.log("creating_loan")
            this.createLoanFlow()
          }
          break;
        case 'LOAN_DETAIL':
          if (this.authService.getUser?.user) this.router.navigate([`/loan/${this.token.currentLoanId}`])
          break;
      }
    }
  }

  createLoanFlow() {
    this.loaderService.showSpinner()
    this.pushingService.create({flowId: this.flowId}).subscribe(r => {
      this.loaderService.hideSpinner()
      console.log(r)
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ?  'error': 'success',
          closable: false,
        }
      ]
      if (!r.error) {
        this.router.navigate(["/verify-documents"])
      }
    })
  }
}
