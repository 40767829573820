<div class="main-f-card">
<ng-container *ngIf="!startedProcess; else elseProcess">
    <div class="grid px-4">
      <div class="col-12 my-4">
        <p>
         {{settings.description}}
        </p>
      </div>
      <div class="col-12 mt-2 comment-rejected" *ngIf="data.comment && data.comment !== ''">
        Motivo de rechazo: <strong>{{data.comment}}</strong>
      </div>
      <div class="col-12">
        <div class="img img-start" style="background-image: url('{{settings.img}}')"></div>
      </div>
      <div class="col-12 button-block-container mt-1 mb-3">
        <ng-container *ngIf="data && data.value; else elseExternalUrl">
          <a label="Empezar proceso" icon="fa-solid fa-up-right-from-square p-button-icon-center" class="p-button text-center justify-content-center button-block" [href]="getLink"  (click)="open()" target="_blank">Empezar proceso</a>
        </ng-container>
        <ng-template #elseExternalUrl>
          <p class="mb-3 text-color-secondary">Por el momento un asesor esta trabajando en generar la liga para iniciar este proceso, se paciente, te notitificaremos cuando el proceso este disponible para avanzar.</p>
          <button pButton pRipple label="Entiendo" icon="fa-solid fa-check p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="onEndProcess.emit(true)"></button>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #elseProcess>
    <div class="grid px-4">
      <div class="col-12 my-4">
        <p>
          Si has completado correctamente el proceso, da clic en he concluido el proceso.
        </p>
      </div>

      <div class="col-12">
        <div class="img img-complete" style="background-image: url('{{settings.img}}')"></div>
      </div>
      <div class="col-12">
        <div class="message">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
        </div>
      </div>
      <div class="col-12 button-block-container mt-1 mb-3">
          <button pButton pRipple label="He concluido el proceso" icon="fa-solid fa-circle-check p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="validateExternalLink()"></button>
      </div>
    </div>
</ng-template>
</div>
