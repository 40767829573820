import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFlow} from "../../../../../data/interfaces/iflow.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IInputForm} from "../../../../../data/interfaces/iinput-form.interface";
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {LoaderService} from "../../../../../data/services/page/loader.service";
import {Message} from "primeng/api";

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss']
})
export class FormComponentComponent implements OnInit {
  responseMessage: Message[] = []
  showMessage = false
  isLoading = false
  @Input() data!: IFlow
  @Output() onEndProcess = new EventEmitter()
  entity!: FormGroup
  inputs: IInputForm[] = []
  patterns: any =  {
    phone52: /^\+52[0-9]{10}$/,
    curp: /^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z]{6}[0-9A-Z]{2}$/,
    clabe: /^\d{18}$/,
    email: /^[a-zA-Z0-9_.+]*[a-zA-Z][a-zA-Z0-9_.+]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  }
  constructor(
    private fb: FormBuilder,
    private flowDataService: ApiFlowDataService,
    private loaderService: LoaderService,
  ) {
  }
  ngOnInit() {
    this.inputs = JSON.parse(this.data.properties.settings)
    console.log(this.inputs)
    this.generateForm(this.inputs)
  }

  validateInput(input: string) {
    const control = this.entity.get(input);
    if (control) {
      let cleaned = control.value.toUpperCase()
      control.setValue(cleaned);
    }
  }

  generateForm (inputs: IInputForm[]) {
    const fields:any = {}

    for (const input of inputs) {
      if(input.typeField !== 'SEPARATOR') {
        const validations = []
        for (const validation of input.validations) {
          switch (validation.type) {
            case 'pattern':
              console.log('pattern', validation.value)
              const pattern = this.patterns[validation.value as keyof typeof this.patterns] ?? new RegExp(validation.value.toString())
              validations.push(Validators.pattern(pattern))
              break
            case 'minlength':
              validations.push(Validators.minLength(+validation.value))
              break
            default:
              validations.push(Validators.required)
              break
          }
        }
        if (input.typeField === 'INPUT_NUMBER') fields[input.id] = [0, validations]
        else fields[input.id] = ['', validations]
      }

    }
    this.entity = this.fb.group(fields)
    console.log(this.entity)
  }

  save() {
    this.responseMessage = []
    this.loaderService.showSpinner()
    this.flowDataService.saveInfo({data: JSON.stringify(this.entity.value), idDocument: this.data.id, idStage: this.data.userDocumentId}).subscribe(r => {
      this.loaderService.hideSpinner()
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ?  'error': 'success',
          closable: false,
        }
      ]
      if (!r.error) {
        setTimeout(() => {
          this.onEndProcess.emit(true)
        }, 4000)
      }
    })

  }
}
