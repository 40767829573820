import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {Router} from "@angular/router";
import {IUserAuthenticated} from "../../../interfaces/iuser-authenticated.interface";
import {ApiClass} from "../../../class/api-class";
import {IFinsusData, IFinsusToken} from "../../../interfaces/ifinsus.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiClass {
  private currentUserSubject: BehaviorSubject<IUserAuthenticated | null>
  public currentUser: Observable<IUserAuthenticated | null>
  public constLocalUserVariable = 'pulpi-user-app'
  constructor(
    private router: Router
  ) {
    super();
    this.path = 'auth'
    this.currentUserSubject = new BehaviorSubject<IUserAuthenticated | null>(
      JSON.parse(<string>localStorage.getItem(this.constLocalUserVariable))
    )
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public get getUser(): IUserAuthenticated | null{
    return this.currentUserSubject.value
  }

  login(data: {email: string, password: string}) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IUserAuthenticated}))
  }

  resetPassword(data: {email: string, password: string}) {
    return this.postCollection(data, 'resetPassword').pipe(map((r) => r as {error: boolean; msg: string; data: boolean}))
  }

  logout() {
    localStorage.removeItem(this.constLocalUserVariable)
    this.currentUserSubject.next(null)
    console.log('mirame')
    this.router.navigate(['/'])
  }

  hasValidToken(data: IFinsusToken) {
    console.log(this.getUser?.user.rfc, data)
    return (new Date().getTime() < (this.getUser?.detailToken?.expirationTime ?? 0) && (data === undefined || this.getUser?.user.rfc === data.rfc))
  }

  public get getToken(): string {
    return this.currentUserSubject.value?.token ?? ''
  }

  updateUserLocalData(user: IUserAuthenticated) {
    if(user) {
      console.log('hellow')
      localStorage.setItem(this.constLocalUserVariable, JSON.stringify(user))
      this.currentUserSubject.next(user)
    }
  }
}
